<template>
  <div>
    <b-card-code title="Add Tag" :before-change="submit">
      <b-card-text>
        <span>Add New Tag </span>
      </b-card-text>
      <validation-observer ref="simpleRules">
        <b-form novalidate class="needs-validation" @submit.prevent>
          <b-row>
            <!-- icon -->
            <b-col md="6">
              <b-form-group
               
                label="Icon"
                label-for="icon"
              >
                <validation-provider #default="{ errors }" name="icon">
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    :state="errors.length > 0 ? false : null"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept=".jpg, .png"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- tag input  -->
            <b-col md="6">
              <label for="input-valid1">Tag Title:</label>
              <b-form-input
                id="input-valid1"
                v-model="data.name"
                :state="data.name.length > 0"
                placeholder="Valid input"
              />
            </b-col>
            <!-- select language box -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-group
                  style="margin-top: 2.5px;"
                  label="Language"
                  label-for="language"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="language"
                    v-model="languageSelect"
                    :state="languageSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="lanuageOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="submit"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { codeValidateTooltips } from './code'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
  },
  
  data() {
    return {
      required,
      
    codeValidateTooltips ,
      ProfilePictureBase64: [],
      success_message: '',
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      languageSelect: null,
      lanuageOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      data: {
        icon: [],
        name: '',
        locale: '',
      },
    }
  },
  methods: {
    // submit new tag API
    submit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.locale = this.languageSelect.value
          this.showDismissibleAlert = false
          if (this.data.icon.includes(',')) {
            this.data.icon = this.data.icon
              .split(',')
              .map((item) => item.trim())
            this.data.icon = this.data.icon[1]
          }
          var item = this.data

          if (item.icon == null || item.icon == '' || item.icon == []) {
            delete item.icon
          }
          axios
            .post('tags', item)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Tags')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.data.icon = e.target.result
        //console.log(vm.data.icon)
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
</style>
